@tailwind base;
@tailwind components;
@tailwind utilities;
@import "fontFace.css";

body {
  position: relative;
}

/* Text Gradient FX */
.text_gradientBGFX {
  position: relative;
}
.text_gradientBGFX:before {
  content: " ";
  background-image: url("../public/bg_gradient.png");
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
  background-position: top;
  background-attachment: fixed;
  background-size: auto 100vh;
  position: absolute;
  z-index: -999999;
}
.text_gradientBGFX div:first-child {
  mix-blend-mode: screen;
  background-color: #fff;
}

/* Shape Gradient FX */
.shape_gradientBGFX {
  position: relative;
}
.shape_gradientBGFX:before {
  content: " ";
  background-image: url("../public/bg_gradient.png");
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
  background-position: top;
  background-attachment: fixed;
  background-size: auto 100vh;
  position: absolute;
  z-index: -999999;
}
.shape_gradientBGFX div:first-child {
  mix-blend-mode: screen;
}

.bg-gradientFX {
  background-image: url("../public/bg_gradient.png");
  background-size: auto 100vh;
}

.bodyZone {
  position: relative;
  z-index: 1;
}

.bodyZone:after,
.bodyZone:before {
  content: " ";
  display: table;
}

.bodyZone:after {
  clear: both;
}

.c {
  font-family: Montserrat;
  font-variation-settings: "wght" 793;
}
